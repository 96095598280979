document.addEventListener('DOMContentLoaded', function(){

    iniciarApp();

});

function iniciarApp() {
    galeriaNuestrasMarcas();
}

function galeriaNuestrasMarcas() {
    const galeria = document.querySelector('.nuestras-marcas');

    /* for(let i = 1 ; i <= 29; i++) {
        const imagen = document.createElement('picture');

        imagen.innerHTML = ` 
        <source srcset="build/img/${i}.avif" type="image/avif">
        <source srcset="build/img/marcas/${i}.webp" type="image/webp">
        <source srcset="build/img/marcas/${i}.jpg" type="image/jpeg">
        <source srcset="build/img/marcas/${i}.png" type="image/png">
        <img width="200" height="300" src="build/img/marcas/${i}.jpg" alt="Imagen Nuestras Marcas">
        `

        galeria.appendChild(imagen);
    } */

    
}